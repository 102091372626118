<template>
  <v-dialog v-model="dialog" @click:outside="closeDialog" scrollable max-width="1200px">
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-divider></v-divider>
      <v-card-text v-if="campaign" class="pt-4">
        <v-form ref="form" v-model="valid" lazy-validation>
          <v-row>
            <v-col cols="12" class="mr-auto py-0">
              <v-text-field
                label="Descripción *"
                required
                :disabled="typeForm == 'V'"
                v-model="campaign.description"
                :rules="requiredRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
              <v-text-field
                label="Periodo Inicio *"
                type="date"
                :rules="requiredRules"
                :disabled="typeForm == 'V'"
                required
                v-model="campaign.startPeriod"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6" class="mr-auto py-0">
              <v-text-field
                label="Periodo Fin *"
                type="date"
                :rules="requiredRules"
                :disabled="typeForm == 'V'"
                required
                v-model="campaign.endPeriod"
              ></v-text-field>
            </v-col>
            <v-col cols="12" class="mr-auto py-0">
              <v-text-field
                label="Observaciones"
                :disabled="typeForm == 'V'"
                v-model="campaign.observations"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn color="error" text @click="closeDialog">Cerrar</v-btn>
        <v-btn v-if="typeForm != 'V'" color="light" text @click="save">Guardar</v-btn>
      </v-card-actions>
    </v-card>

    <div v-if="showLoading" class="progress-background">
      <v-progress-circular :size="70" :width="7" color="primary" indeterminate></v-progress-circular>
    </div>
  </v-dialog>
</template>

<script>
import CampanaService from '../services/CampanaService';
import Swal from 'sweetalert2';

export default {
  name: 'FormularioCampana',
  components: {},
  computed: {},
  props: {
    campaign: {
      type: Object,
      default: () => {
        return {
          campaignId: '',
          description: '',
          startPeriod: '',
          endPeriod: '',
          observations: ''
        };
      }
    },
    dialog: {
      type: Boolean,
      default: false
    },
    typeForm: {
      type: String,
      default: 'N'
    }
  },
  data: () => ({
    typesQuestion: [],
    service: null,
    showLoading: false,
    valid: false,
    edit: false,
    mostrarFormato: false,
    isView: null,
    title: '',
    requiredRules: [(v) => !!v || 'El campo es obligatoria']
  }),
  watch: {
    async dialog(val) {
      if (val) {
        this.title = this.typeForm == 'N' ? 'Nueva Campaña' : this.typeForm == 'E' ? 'Editar Campaña' : 'Ver Campaña';
        if (this.$refs.form) await this.$refs.form.resetValidation();
      }
    }
  },
  methods: {
    async save() {
      if (this.$refs.form.validate(true)) {
        this.messageFormError = '';
        let question = '¿Desea guardar los datos?';
        if (this.edit) question = '¿Desea actualizar los datos?';

        const { isConfirmed } = await this.alertDialogYesNo('question', question);

        if (isConfirmed) {
          if (this.typeForm == 'E') {
            const res = await this.service.put().execResource('actualizarCampana', {
              campaign: this.campaign
            });
            if (res.status) {
              await this.alertDialog('success', res.data);
              this.closeDialog();
              this.$emit('listar');
            } else {
              await this.alertDialog('error', res.data);
            }
          } else {
            const res = await this.service.post().execResource('guardarCampana', {
              campaign: this.campaign
            });
            if (res.status) {
              await this.alertDialog('success', res.data);
              this.closeDialog();
              this.$emit('listar');
            } else {
              await this.alertDialog('error', res.data);
            }
          }
        }
      }
    },
    async alertDialogYesNo(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        showCancelButton: true,
        showConfirmButton: true,
        cancelButtonText: `Cancelar`,
        confirmButtonText: `Guardar`,
        reverseButtons: true
      });
    },
    async alertDialog(type, title) {
      return await Swal.fire({
        icon: type,
        title: title,
        confirmButtonText: `OK`
      });
    },
    closeDialog() {
      this.$emit('closeDialog');
    }
  },
  created() {
    this.service = this.$httpService(new CampanaService(), this);
    //this.initialize();
  }
};
</script>
