class CampanaService {
  constructor() {}
  resources = () => ({
    guardarCampana: {
      uri: `/v1/campaign`,
      method: ['post']
    },
    actualizarCampana: {
      uri: `/v1/campaign`,
      method: ['put']
    },
    listarCampanas: {
      uri: `/v1/listcampaign`,
      method: ['get']
    },
    cambiarEstadoCampana: {
      uri: `/v1/statecampaign`,
      method: ['put']
    }
  });
}
export default CampanaService;
